/* eslint-disable no-prototype-builtins */
import "typeface-montserrat"
import "./src/scss/index.scss"
import React from "react"
import AppProvider from "./src/context/app-context"

const addRewardfulScript = (url, id) => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  script.setAttribute("data-rewardful", id)

  document.getElementsByTagName("head")[0].appendChild(script)
}

const initializeRewardful = () => {
  ;(function (w, r) {
    w._rwq = r
    w[r] =
      w[r] ||
      function () {
        ;(w[r].q = w[r].q || []).push(arguments)
      }
  })(window, "rewardful")
}

const addUserWayScript = () => {
  const s = document.createElement("script")
  s.setAttribute("data-account", "AxhTchKRJL")
  s.setAttribute("src", "https://cdn.userway.org/widget.js")
  s.setAttribute("data-position", 5)
  s.setAttribute("data-size", "small")
  s.setAttribute("data-color", "#0e7874")
  s.setAttribute("data-type", "1")
  document.getElementsByTagName("body")[0].appendChild(s)
}

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return <AppProvider>{element}</AppProvider>
}

export const onClientEntry = () => {
  initializeRewardful()
  addRewardfulScript("https://r.wdfl.co/rw.js", "0412d5")
  addUserWayScript()
}
