import { gql } from "@apollo/client"

const FRAGMENT_USER = gql`
  fragment UserFragment on User {
    id
    first_name
    last_name
    email
    secondary_email
    is_subscribed
    has_mfa
    is_mfa_verified
    requires_email_verification
    is_email_verified
    created_at
    last_login_at
    archived_lists_count
    display_intake
    add_beneficiary
    account_source
    membership_plan
    signup_scenario
    organization {
      id
      name
      logo
    }
    is_on_trial
    trial_expiry
    subscription_due_date
    subscription_amount
    files {
      id
      task_id
      url
      name
      type
      size
    }
    abilities {
      post_loss_planning_lists
      pre_planning_lists
      custom_post_loss_planning_tasks
      custom_pre_planning_tasks
      collaborators
      storage_limit
      beneficiaries
      add_comments
    }
    plans {
      id
      user_id
      name
      date_of_death
      preplanCount
      modified_by_user
      intake_response {
        scenario
        funeral_home
        service
        funeral_type
        loved_one_was
      }
      topics {
        uid
        title
      }
      created_at
      user {
        full_name
        first_name
      }
      abilities {
        delete
      }
      collaborators {
        pending {
          team_id
          email
          status
          role
          timing
          name
          created_at
        }
        team_members {
          team_id
          email
          role
          status
          timing
          name
        }
      }
    }
    articles {
      id
      uid
      prismic_id
      title
      image
      image_alt
    }
    nps {
      score
      detracting_score_answer
    }
    sharedAsCollaboratorPrePlanningPlans {
      id
      name
      user_id
      created_at
      user {
        full_name
        first_name
      }
      topics {
        id
        name
      }
    }
    sharedAsBeneficiaryPrePlanningPlans {
      id
      name
      user_id
      created_at
      updated_at
      beneficiary_is_admin
      user {
        full_name
        first_name
      }
      topics {
        id
        name
      }
    }
    sharedPlans {
      id
      user_id
      name
      created_at
      user {
        full_name
        first_name
      }
      topics {
        uid
        title
      }
    }
    prePlanningPlans {
      id
      name
      modified_by_user
      created_at
      collaborators {
        pending {
          team_id
          email
          role
          status
          timing
          name
          created_at
        }
        team_members {
          team_id
          email
          role
          status
          timing
          name
        }
      }
      topics {
        id
        name
        section_id
        sort_order
        is_gated
      }
    }
    card_last_four
    subscription_price_id
    pre_planning_intake_count
    membership_plan
    email_verified_at
    user_type
  }
`

export { FRAGMENT_USER }
