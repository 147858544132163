import React from "react"
import { transitions } from "react-alert"
import PropTypes from "prop-types"

const alertOptions = {
  timeout: 5000,
  transition: transitions.FADE,
  offset: "32px 0px",
  containerStyle: {
    zIndex: 1000,
  },
}

const AlertTemplate = ({ style, options, message, close }) => {
  let icon

  switch (options.type) {
    case "success":
      icon = (
        <i className="material-icons alert-icon text-success-dark border-success-dark">
          check_circle_outline
        </i>
      )
      break
    case "info":
      icon = (
        <i className="material-icons alert-icon text-primary border-primary">
          info_outline
        </i>
      )
      break
    case "error":
      icon = (
        <i className="material-icons alert-icon text-error-dark">
          error_outline
        </i>
      )
      break
    default:
  }

  return (
    <div className="relative" style={style}>
      <div
        className={`${
          options.type === "error"
            ? "error-alert"
            : options.type === "info"
            ? "task-alert"
            : "default-alert"
        } 
          flex flex-row items-center body-text`}
      >
        <span className="text-base leading-none mr-4 md:mr-2">{icon}</span>
        <span className="text-base leading-tight pb-0 mb-0 flex-grow">
          {options.type === "error" ? (
            <span className="font-semibold text-grey-darker mr-1">
              There&apos;s been an error.
            </span>
          ) : options.type === "success" ? (
            <span className="font-semibold text-grey-darker mr-1">
              Success!
            </span>
          ) : (
            <span></span>
          )}
          <span>{message}</span>
        </span>
        <button
          className={`material-icons focus:outline-none text-grey-darker ml-2`}
          onClick={close}
        >
          close
        </button>
      </div>
    </div>
  )
}

AlertTemplate.propTypes = {
  style: PropTypes.object,
  options: PropTypes.object,
  message: PropTypes.string,
  close: PropTypes.func,
}

export default AlertTemplate
export { alertOptions }
