import { gql } from "@apollo/client"
import { FRAGMENT_USER } from "../fragments/user"

const MUTATION_LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(input: { username: $username, password: $password }) {
      access_token
      refresh_token
      expires_at
      user {
        ...UserFragment
      }
    }
  }
  ${FRAGMENT_USER}
`

const MUTATION_LOGOUT = gql`
  mutation logout {
    logout {
      status
      message
    }
  }
`

const MUTATION_REGISTER = gql`
  mutation register(
    $first_name: String!
    $last_name: String!
    $email: String!
    $password: String!
    $password_confirmation: String!
    $scenario: String!
    $referral: String
    $price_id: String
    $plan_for: String
    $relationship: String
    $relationship_other: String
  ) {
    register(
      input: {
        first_name: $first_name
        last_name: $last_name
        email: $email
        password: $password
        password_confirmation: $password_confirmation
        scenario: $scenario
        referral: $referral
        price_id: $price_id
        plan_for: $plan_for
        relationship: $relationship
        relationship_other: $relationship_other
      }
    ) {
      tokens {
        access_token
        refresh_token
        expires_at
        user {
          ...UserFragment
        }
      }
      status
    }
  }
  ${FRAGMENT_USER}
`

const MUTATION_FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(input: { email: $email }) {
      status
      message
    }
  }
`

const MUTATION_VERIFY_MFA = gql`
  mutation verifyMFA($code: String!, $disable: Boolean) {
    verifyMFA(input: { code: $code, disable: $disable }) {
      status
      message
    }
  }
`

const MUTATION_SETUP_MFA = gql`
  mutation setupMFA($platform: String!) {
    setupMFA(input: { platform: $platform }) {
      status
      message
    }
  }
`

const MUTATION_RESEND_MFA = gql`
  mutation resendMFA {
    resendMFA {
      status
      message
    }
  }
`

const MUTATION_RESEND_VERIFICATION_EMAIL = gql`
  mutation resendVerificationEmail {
    resendVerificationEmail {
      status
      message
    }
  }
`

const MUTATION_VERIFY_EMAIL = gql`
  mutation verifyEmail($code: String!) {
    verifyEmail(input: { code: $code }) {
      user {
        ...UserFragment
      }
    }
  }
  ${FRAGMENT_USER}
`

const MUTATION_PARTNER_REGISTER_USER = gql`
  mutation partnersRegisterUser(
    $email: String!
    $password: String!
    $password_confirmation: String!
    $partners_slug: String!
    $registration_code: String!
    $first_name: String!
    $last_name: String!
    $scenario: String!
    $plan_for: String
    $relationship: String
    $relationship_other: String
  ) {
    partnersRegisterUser(
      input: {
        email: $email
        password: $password
        password_confirmation: $password_confirmation
        partners_slug: $partners_slug
        first_name: $first_name
        last_name: $last_name
        scenario: $scenario
        registration_code: $registration_code
        plan_for: $plan_for
        relationship: $relationship
        relationship_other: $relationship_other
      }
    ) {
      tokens {
        access_token
        refresh_token
        expires_at
        user {
          ...UserFragment
        }
      }
      status
    }
  }
  ${FRAGMENT_USER}
`

const MUTATION_RESET_PASSWORD = gql`
  mutation resetForgotPassword(
    $token: String!
    $email: String!
    $password: String!
    $password_confirmation: String!
  ) {
    resetForgotPassword(
      input: {
        token: $token
        email: $email
        password: $password
        password_confirmation: $password_confirmation
      }
    ) {
      status
      message
    }
  }
`

const NEWSLETTER_SIGNUP = gql`
  mutation newsletterSignup($email: EmailAddress!) {
    newsletterSignup(email: $email)
  }
`

export {
  MUTATION_LOGIN,
  MUTATION_LOGOUT,
  MUTATION_REGISTER,
  MUTATION_FORGOT_PASSWORD,
  MUTATION_VERIFY_MFA,
  MUTATION_SETUP_MFA,
  MUTATION_RESEND_MFA,
  MUTATION_RESEND_VERIFICATION_EMAIL,
  MUTATION_VERIFY_EMAIL,
  MUTATION_PARTNER_REGISTER_USER,
  MUTATION_RESET_PASSWORD,
  NEWSLETTER_SIGNUP,
}
