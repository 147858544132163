import React from "react"
import { useQuery, useMutation } from "@apollo/client"
import {
  setTokenData,
  removeTokenData,
  getToken,
  isTokenExpired,
} from "../utils/auth"
import { isBrowser } from "../utils/browser"
import {
  MUTATION_LOGIN,
  MUTATION_REGISTER,
  MUTATION_VERIFY_MFA,
  MUTATION_RESEND_MFA,
  MUTATION_RESEND_VERIFICATION_EMAIL,
  MUTATION_VERIFY_EMAIL,
  MUTATION_PARTNER_REGISTER_USER,
} from "../graphql/mutations/auth"
import { QUERY_CURRENT_USER } from "../graphql/queries/auth"

const AuthContext = React.createContext()

function AuthProvider(props) {
  const {
    data,
    loading,
    refetch,
    startPolling: startUserPolling,
    stopPolling: stopUserPolling,
  } = useQuery(QUERY_CURRENT_USER)
  const [signin] = useMutation(MUTATION_LOGIN)
  const [signup] = useMutation(MUTATION_REGISTER)
  const [verifyMFA] = useMutation(MUTATION_VERIFY_MFA)
  const [resendMFA] = useMutation(MUTATION_RESEND_MFA)
  const [resendVerificationEmail] = useMutation(
    MUTATION_RESEND_VERIFICATION_EMAIL
  )
  const [verifyEmail] = useMutation(MUTATION_VERIFY_EMAIL)
  const [signupPartner] = useMutation(MUTATION_PARTNER_REGISTER_USER)

  const login = (username, password) => {
    return signin({ variables: { username, password } }).then((res) => {
      if (res && res.data && res.data.login && res.data.login.access_token) {
        setTokenData(res.data.login)
        return refetch().then(() => {
          return res
        })
      } else {
        throw Error("No token returned")
      }
    })
  }

  const register = (variables) => {
    return signup({
      variables,
    }).then((res) => {
      if (res && res.data && res.data.register && res.data.register.tokens) {
        setTokenData(res.data.register.tokens)
        return refetch().then(() => {
          sessionStorage.setItem("first_login", true)
          return res
        })
      } else {
        throw Error("Registration Unsuccessful")
      }
    })
  }

  const MFAVerification = (code, disable = false) => {
    return verifyMFA({ variables: { code, disable } }).then((res) => {
      if (res?.data?.verifyMFA?.status) {
        return res
      } else {
        throw Error("MFA verification unsuccessful")
      }
    })
  }

  const MFAResend = () => {
    return resendMFA().then((res) => {
      if (res?.data?.resendMFA?.status) {
        return res
      } else {
        throw Error("MFA resend unsuccessful")
      }
    })
  }

  const ResendVerificationEmail = () => {
    return resendVerificationEmail().then((res) => {
      if (res?.data?.resendVerificationEmail?.status) {
        return res
      } else {
        throw Error("Email verification resent unsuccessful")
      }
    })
  }

  const VerifyEmail = (token) => {
    return verifyEmail(token).then((res) => {
      if (res?.data?.verifyEmail?.user) {
        return refetch().then(() => {
          return res
        })
      } else {
        throw Error("Email verification unsuccessful")
      }
    })
  }

  const logout = async () => {
      removeTokenData()

      try {
        await refetch()
      } catch (error) {
        // console.log(error)
      }

      return true
  }

  const isLoggedIn = () => {
   return (
      data &&
      typeof getToken() === "string" &&
      getToken().trim() !== "" &&
      !isTokenExpired()
    )
  }

  if (isBrowser() && loading) {
    return null
  }

  const registerPartnerUser = (variables) => {
    return signupPartner({
      variables,
    }).then((res) => {
      if (
        res &&
        res.data &&
        res.data.partnersRegisterUser &&
        res.data.partnersRegisterUser.tokens
      ) {
        setTokenData(res.data.partnersRegisterUser.tokens)
        return refetch().then(() => {
          sessionStorage.setItem("first_login", true)
          return res
        })
      } else {
        throw Error("Registration Unsuccessful")
      }
    })
  }

  return (
    <AuthContext.Provider
      value={{
        data,
        login,
        logout,
        register,
        isLoggedIn,
        refetchUser: refetch,
        startUserPolling,
        stopUserPolling,
        MFAVerification,
        MFAResend,
        ResendVerificationEmail,
        VerifyEmail,
        registerPartnerUser,
      }}
      {...props}
    />
  )
}

const useAuth = () => React.useContext(AuthContext)

export { AuthProvider, useAuth }
