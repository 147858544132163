import React, { useReducer, useContext } from "react"
import PropTypes from "prop-types"
import { toggleArr } from "../../utils/array"

const PlanTopicsContext = React.createContext()

const initialState = {}

const reducer = (state, action) => {
  let topic
  let ideaIndex
  let ideas

  switch (action.type) {
    case "UPDATE_TOPIC":
      state = { ...state, [action.topicId]: action.topic }
      return state

    case "UPDATE_QUESTION_ANSWER":
      topic = state[action.topicId]
        ? JSON.parse(JSON.stringify(state[action.topicId]))
        : null
      if (!topic) return state
      topic.questions[action.currentIndex].answer = action.answer
      state = { ...state, [action.topicId]: topic }
      return state

    case "UPDATE_TOPIC_BOOKMARKS":
      topic = state[action.topicId]
        ? JSON.parse(JSON.stringify(state[action.topicId]))
        : null
      if (!topic) return state
      topic.bookmarked_questions = toggleArr(
        topic.bookmarked_questions,
        action.question,
        (item) => item.id
      )
      state = { ...state, [action.topicId]: topic }
      return state

    case "UPDATE_QUESTION_ANSWER_PARTIAL":
      topic = state[action.topicId]
        ? JSON.parse(JSON.stringify(state[action.topicId]))
        : null
      if (!topic) return state
      if (topic.questions[action.currentIndex].answer) {
        topic.questions[action.currentIndex].answer.details = action.details
        topic.questions[action.currentIndex].answer.form_answers =
          action.form_answers
        topic.questions[action.currentIndex].answer.progress_state =
          "PARTIALLY_DONE"
        topic.questions[action.currentIndex].answer.updated_at = Date.now()
      } else {
        let answer = {
          details: action.details,
          form_answers: action.form_answers,
          progress_state: "PARTIALLY_DONE",
          updated_at: Date.now(),
        }
        topic.questions[action.currentIndex].answer = answer
      }

      state = { ...state, [action.topicId]: topic }
      return state

    case "UPDATE_QUESTION_ANSWER_FILES":
      topic = state[action.topicId]
        ? JSON.parse(JSON.stringify(state[action.topicId]))
        : null
      if (!topic) return state
      if (topic.questions[action.currentIndex].answer) {
        topic.questions[action.currentIndex].answer.files.unshift(action.file)
      }
      state = { ...state, [action.topicId]: topic }
      return state

    case "REMOVE_QUESTION_ANSWER_FILE":
      topic = state[action.topicId]
        ? JSON.parse(JSON.stringify(state[action.topicId]))
        : null
      if (!topic) return state
      if (topic.questions[action.currentIndex].answer) {
        const files = topic.questions[action.currentIndex].answer.files
        const currentFiles = files.filter((file) => {
          return file.id !== action.file.id
        })
        topic.questions[action.currentIndex].answer.files = currentFiles
      }
      state = { ...state, [action.topicId]: topic }
      return state

    case "APPEND_IDEA":
      topic = state[action.topicId]
        ? JSON.parse(JSON.stringify(state[action.topicId]))
        : null
      if (!topic) return state
      topic.ideas.push(action.idea)
      state = { ...state, [action.topicId]: topic }
      return state

    case "UPDATE_IDEA":
      topic = state[action.topicId]
        ? JSON.parse(JSON.stringify(state[action.topicId]))
        : null
      if (!topic) return state
      topic.ideas[action.currentIndex] = {
        ...topic.ideas[action.currentIndex],
        id: action.idea.id,
        type: action.idea.type,
        title: action.idea.title,
      }
      state = { ...state, [action.topicId]: topic }
      return state

    case "UPDATE_IDEA_ANSWER":
      topic = state[action.topicId]
        ? JSON.parse(JSON.stringify(state[action.topicId]))
        : null
      if (!topic) return state
      ideaIndex = topic.ideas.findIndex(
        (idea) => idea.id + idea.type === action.ideaId
      )
      topic.ideas[ideaIndex].answer = action.answer
      state = { ...state, [action.topicId]: topic }
      return state

    case "REMOVE_IDEA":
      topic = state[action.topicId]
        ? JSON.parse(JSON.stringify(state[action.topicId]))
        : null
      if (!topic) return state
      ideas = topic.ideas.filter((_, index) => {
        return index !== action.currentIndex
      })
      topic.ideas = ideas
      state = { ...state, [action.topicId]: topic }
      return state
    default:
      return state
  }
}

const PlanTopicsProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <PlanTopicsContext.Provider
      value={{ planTopics: state, planTopicDispatch: dispatch }}
      {...props}
    />
  )
}
PlanTopicsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const usePlanTopics = () => useContext(PlanTopicsContext)

export { PlanTopicsProvider, usePlanTopics }
