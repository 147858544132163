import { ApolloClient, ApolloLink, InMemoryCache, gql } from "@apollo/client"
import { TokenRefreshLink } from "apollo-link-token-refresh"
import { isTokenExpired, getToken, setTokenData, removeTokenData } from "./auth"
import { makePromise, execute } from "apollo-link"
import { navigate } from "gatsby"
import createUploadLink from "apollo-upload-client/public/createUploadLink"
import { buildAxiosFetch } from "@lifeomic/axios-fetch"
import axios from "axios"
import fetch from "isomorphic-fetch"
import { isBrowser } from "./browser"

const httpLink = createUploadLink({
  uri: process.env.GATSBY_LANTERN_APP_URL ?? process.env.LANTERN_APP_URL,
  fetch: buildAxiosFetch(axios, (config, input, init) => ({
    ...config,
    onUploadProgress: init.onUploadProgress,
  })),
})

const QUERY_REFRESH_TOKEN = gql`
  mutation refreshToken($refresh_token: String!) {
    refreshToken(input: { refresh_token: $refresh_token }) {
      access_token
      refresh_token
      expires_at
    }
  }
`

const refreshTokenLink = new TokenRefreshLink({
  accessTokenField: "access_token",
  isTokenValidOrUndefined: () =>
    !isTokenExpired() || typeof getToken() !== "string",
  fetchAccessToken: () => {
    const operation = {
      query: QUERY_REFRESH_TOKEN,
      variables: {
        refresh_token: window.localStorage.getItem("refresh_token"),
      },
    }
    return makePromise(execute(httpLink, operation))
  },
  handleResponse: () => (response) => {
    setTokenData(response.data.refreshToken)
    return response.data.refreshToken
  },
  handleError: () => {
    removeTokenData(() => navigate("/"))
  },
})

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => ({
    headers: {
      authorization: `Bearer ${getToken()}`,
      "client-url": isBrowser() ? window.location.origin : "",
      ...headers,
    },
  }))
  return forward(operation)
})

const link = ApolloLink.from([refreshTokenLink, authLink, httpLink])

const cache = new InMemoryCache({
  typePolicies: {
    // As there is no unique ID for the category and name can be repeated or empty string
    // then assume that the keyfield for it to be cached is the name and it's tasks
    Category: {
      keyFields: ["name", "tasks"],
    },
    FormAnswers: {
      keyFields: ["form_key", "plan_id"],
    },
  },
})

// apollo client setup
const client = new ApolloClient({
  fetch,
  link,
  cache,
})

export default client
