import React from "react"
import client from "../utils/apollo-client"
import { ApolloProvider } from "@apollo/client"
import { PropTypes } from "prop-types"
import { AuthProvider } from "./auth-context"
import { UserProvider } from "./user-context"
import { Provider as AlertProvider } from "react-alert"
import AlertTemplate, { alertOptions } from "../utils/alert"
import { PlanTopicsProvider } from "./pre-planning/plan-topic-context"
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews"
function AppProvider({ children }) {
  return (
    <ApolloProvider client={client}>
      <PrismicPreviewProvider>
        <AuthProvider>
          <UserProvider>
            <PlanTopicsProvider>
              <AlertProvider template={AlertTemplate} {...alertOptions}>
                {children}
              </AlertProvider>
            </PlanTopicsProvider>
          </UserProvider>
        </AuthProvider>
      </PrismicPreviewProvider>
    </ApolloProvider>
  )
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppProvider
