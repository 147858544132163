import React from "react"
import { useAuth } from "./auth-context"

const UserContext = React.createContext()

const UserProvider = (props) => {
  const { data } = useAuth()

  return (
    <UserContext.Provider value={data ? data.currentUser : null} {...props} />
  )
}

const useUser = () => React.useContext(UserContext)

export { UserProvider, useUser }
