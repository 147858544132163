import { gql } from "@apollo/client"
import { FRAGMENT_USER } from "../fragments/user"

const QUERY_CURRENT_USER = gql`
  query currentUser {
    currentUser {
      ...UserFragment
    }
  }
  ${FRAGMENT_USER}
`

export { QUERY_CURRENT_USER }
