import { isBrowser } from "../utils/browser"

const setTokenData = (token) => {
  if (!isBrowser() || !token) {
    return
  }
  window.localStorage.setItem("token", token.access_token)
  window.localStorage.setItem("refresh_token", token.refresh_token)
  window.localStorage.setItem(
    "token_expires_at", new Date(token.expires_at).toString()
  )
}

const removeTokenData = () => {
  if (!isBrowser()) {
    return
  }

  window.localStorage.removeItem("token")
  window.localStorage.removeItem("intendedURL")
  window.localStorage.removeItem("refresh_token")
  window.localStorage.removeItem("token_expires_at")
  window.localStorage.removeItem("latestInvoiceId")
  window.localStorage.removeItem("latestInvoicePaymentIntentStatus")
  window.localStorage.removeItem("latestInvoiceCoupon")
  window.sessionStorage.removeItem("collaborator")
}

const getToken = () => isBrowser() && window.localStorage.getItem("token")

const isTokenExpired = () => {
  if (!isBrowser()) {
    return false
  }

  const expirationDate = new Date(
    window.localStorage.getItem("token_expires_at")
  )
  return new Date() > expirationDate
}

export { getToken, setTokenData, removeTokenData, isTokenExpired }
